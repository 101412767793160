(function(){
	'use strict';

	/**
	 * Ready events function
	 *
	 * This is run immediately after the DOM is loaded. Other assets may or may
	 * not be loaded yet.
	 */
	jQuery( function() {
		console.log('hello from the missing form');


		function auto_complete_fields() {
			jQuery('.gform_wrapper').each(function(){
				$(this).find('.name_first input').attr('autocomplete', 'given-name');
				$(this).find('.name_last input').attr('autocomplete', 'family-name');
			});

			jQuery('.add-autocomplete-email').each(function(){
				$(this).find('input[type="email"]').attr('autocomplete', 'email');
			});

			jQuery('.add-autocomplete-organization').each(function(){
				$(this).find('input').attr('autocomplete', 'organization');
			});
			jQuery('.add-autocomplete-organization-title').each(function(){
				$(this).find('input').attr('autocomplete', 'organization-title');
			});
		}

		auto_complete_fields();
	/**
	* Gravity Form Functionality
	* Accessibility. When a form is submited with an error, focus on the first
	* Note: requires a form to be sent via ajax
	*/
	jQuery(document).bind('gform_post_render', function(){
		$('.validation_message').remove(); // Remove default validation message

		// Remove some unhelpful classes/attributes on render
		var validation_message = '<div class="gfield_description validation_message">This field is required.</div>';

		// Append new message after each invalid field
		jQuery('input[aria-invalid="true"]').each(function(e){
			$(validation_message).insertAfter(this);
		});

		$('.gform_button').attr("tabindex", "0");
		$('.gform_wrapper form').find('input, textarea, select').attr("tabindex", "0");
		auto_complete_fields();
	});

	// Normal forms. Outside of modals
	$('input[type=submit]').on('click', function(){
		var currentForm = $(this).closest('form');
		var currentFormId = $(currentForm).attr('id');

		jQuery(document).bind('gform_post_render', function(){
			jQuery('.gform_wrapper label').parent().css('position','relative');
			$('.form-assistive').remove();

			var requiredInputs = $('#' + currentFormId).find('[aria-required="true"]');
			var counter = 1;

			$(requiredInputs).each(function(){
				if( !$(this).val() ) {
					$(this).addClass('empty');
					if (counter == 1) {
						// Post render within click necessary for form in modal to add this class to inputs
						$(this).addClass('first-element');
						$(this).focus();

						var relativeID = $(this).attr('id');
						console.log(relativeID);

						/**
						 * Because various gravity form fields structure labels and inputs
						 * differently, let's ensure consistent interaction without the need to style each separately and add 50 lines to our css.
						 */

							// Animating (absolute) labels always need a relative parent
					   	 jQuery('.gform_wrapper label').parent().css('position','relative');

						  // On load. Set labels as active if they have content
						  $('.gform_wrapper form input:not([type="checkbox"]), form textarea').each(function(){ // Remove class if focus leaves
						 	 var inputID = $(this).attr('id');
						 	 var currentForm = $(this).closest('form');
						 	 var availableLabels = $(currentForm).find('label');
						 	 var inputValue = $(this).val();


							 jQuery(availableLabels).each(function(){
								var labelForAttribute = $(this).attr('for');

								if (labelForAttribute == relativeID) {
									$(this).addClass('activate-label');
								} else if (labelForAttribute == inputID && inputValue != '') {
									$(this).addClass('activate-label');
								}

							 });

						  });

						  	 // Focus
						  	 jQuery('.gform_wrapper form input:not([type="checkbox"]), form textarea').on('focus', function(){
						  		 var relativeID = $(this).attr('id');
						  		 var currentForm = $(this).closest('form');
						  		 var availableLabels = $(currentForm).find('label');

								 jQuery(availableLabels).each(function(){
									var labelForAttribute = $(this).attr('for');
									if (labelForAttribute == relativeID) {
										$(this).addClass('activate-label');
									}
								 });
						  	 });

						  	 // On blur — or tabbing away from an input
						  	 $('.gform_wrapper form input:not([type="checkbox"]), form textarea').on('blur', function(){ // Remove class if focus leaves
						  		 var relativeID = $(this).attr('id');
						  		 var currentForm = $(this).closest('form');
						  		 var availableLabels = $(currentForm).find('label');
						  		 var inputValue = $(this).val();

								 jQuery(availableLabels).each(function(){
									var labelForAttribute = $(this).attr('for');
									if (labelForAttribute == relativeID && inputValue == '') {
										$(this).removeClass('activate-label');
									}
								 });
						  	 });

					}
					counter++;
				} else {

				}
			});

		});
	});


	jQuery( function() {

			/**
			 * Because various gravity form fields structure labels and inputs
			 * differently, let's ensure consistent interaction without the need to style each separately and add 50 lines to our css.
			 */
			 // Animating (absolute) labels always need a relative parent
			 jQuery('.gform_wrapper label').parent().css('position','relative');

			 // Focus
			 jQuery('.gform_wrapper form input:not([type="checkbox"]), .gform_wrapper form textarea').on('focus', function(){
				 var relativeID = $(this).attr('id');
				 var currentForm = $(this).closest('form');
				 var availableLabels = $(currentForm).find('label');

				 jQuery(availableLabels).each(function(){
					var labelForAttribute = $(this).attr('for');
					if (labelForAttribute == relativeID) {
						console.log(this);
						$(this).addClass('activate-label');
					}
				 });

			 });

			 // On blur — or tabbing away from an input
			 $('.gform_wrapper form input:not([type="checkbox"]), .gform_wrapper form textarea').on('blur', function(){ // Remove class if focus leaves
				 var relativeID = $(this).attr('id');
				 var currentForm = $(this).closest('form');
				 var availableLabels = $(currentForm).find('label');
				 var inputValue = $(this).val();
				 jQuery(availableLabels).each(function(){
					var labelForAttribute = $(this).attr('for');
					if (labelForAttribute == relativeID && inputValue == '') {
						$(this).removeClass('activate-label');
					}
				 });
			 });


		});


	});

})();
